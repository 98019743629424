import React from "react";
import DateTimePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { dateTimeWithSecondsFormat, parseNumber } from "../../../lib";
import moment from "moment";

export default class Configuration extends React.PureComponent {
  render() {
    const {
      getErrorClassName,
      onChange,
      raffle: {
        cadTicketPrice,
        cociCcProcessingFeePercentage,
        daysRemaining,
        drawingDate,
        endDate,
        fromCadConversion,
        fromGbpConversion,
        gbpTicketPrice,
        id: raffleId,
        isActive,
        isPublished,
        manualTicketEntryDeadline,
        numberOfTicketsWithFeeCap,
        resultsPostingDate,
        startDate,
        ticketFeePercentage,
        usdTicketPrice,
      },
      submitAttempted,
    } = this.props;

    return (
      <div className="new-raffle-step">
        <div className="new-raffle-step-section">
          <p className="xl-text">Configuration</p>
        </div>
        <div className="new-raffle-step-section">
          <p className="medium-text fw-700 mb-16">Grand Draw Dates</p>
          <div className="flex">
            <div>
              <div className="raffle-date-wrapper">
                <label className="raffle-label-wide">Start Date</label>
                <p className="small-text mb-0">
                  {startDate
                    ? moment(startDate).format("MMMM D, YYYY h:mm:ss a")
                    : ""}
                </p>
              </div>
              <div className="raffle-date-wrapper">
                <label className="raffle-label-wide">
                  Shluchim Ticket Entry Deadline
                </label>
                <DateTimePicker
                  className={getErrorClassName(
                    "custom-input",
                    submitAttempted && !manualTicketEntryDeadline
                  )}
                  dateFormat="MM/DD/YYYY h:mm a"
                  disabled={!endDate}
                  id="manualTicketEntryDeadline"
                  minDate={moment(endDate)}
                  onChange={(date) =>
                    onChange(
                      "manualTicketEntryDeadline",
                      date ? date.format(dateTimeWithSecondsFormat) : null
                    )
                  }
                  selected={
                    manualTicketEntryDeadline
                      ? moment(manualTicketEntryDeadline)
                      : null
                  }
                  showTimeSelect={true}
                  tabIndex={10}
                  timeIntervals={1}
                />
              </div>
              <div className="raffle-date-wrapper">
                <label className="raffle-label-wide">
                  Results Posting Date
                </label>
                <DateTimePicker
                  className={getErrorClassName(
                    "custom-input",
                    submitAttempted && !resultsPostingDate
                  )}
                  dateFormat="MM/DD/YYYY h:mm a"
                  id="resultsPostingDate"
                  minDate={moment(endDate)}
                  onChange={(date) =>
                    onChange(
                      "resultsPostingDate",
                      date ? date.format(dateTimeWithSecondsFormat) : null
                    )
                  }
                  selected={
                    resultsPostingDate ? moment(resultsPostingDate) : null
                  }
                  showTimeSelect={true}
                  tabIndex={10}
                  timeIntervals={1}
                />
              </div>
            </div>
            <div className="ml-48">
              <div className="raffle-date-wrapper">
                <label className="raffle-label-wide">End Date</label>
                <p className="small-text mb-0">
                  {endDate
                    ? moment(endDate).format("MMMM D, YYYY h:mm:ss a")
                    : ""}
                </p>
              </div>
              <div className="raffle-date-wrapper">
                <label className="raffle-label-wide">Drawing Date</label>
                <DateTimePicker
                  className={getErrorClassName(
                    "custom-input",
                    submitAttempted && !drawingDate
                  )}
                  dateFormat="MM/DD/YYYY h:mm a"
                  id="drawingDate"
                  minDate={moment(endDate)}
                  onChange={(date) =>
                    onChange(
                      "drawingDate",
                      date ? date.format(dateTimeWithSecondsFormat) : null
                    )
                  }
                  selected={drawingDate ? moment(drawingDate) : null}
                  showTimeSelect={true}
                  tabIndex={10}
                  timeIntervals={1}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="new-raffle-step-section">
          <p className="medium-text fw-700 mb-16">Ticket Prices and Info</p>
          <div className="flex flex-align-center mb-16">
            <div className="flex flex-align-center">
              <label className="accent-text small-text raffle-label">USD</label>
              <NumberFormat
                className={getErrorClassName(
                  "custom-input dollar-input",
                  submitAttempted && !usdTicketPrice
                )}
                disabled={raffleId && (!daysRemaining || isActive)}
                name="usdTicketPrice"
                onChange={(e) =>
                  onChange("usdTicketPrice", parseNumber(e.target.value))
                }
                tabIndex={20}
                value={usdTicketPrice}
                decimalScale={2}
                thousandSeparator={true}
              />
            </div>
            <div className="flex flex-align-center ml-40">
              <label className="accent-text small-text raffle-label">
                Ticket Fee Cap
              </label>
              <div className="relative">
                <NumberFormat
                  className={getErrorClassName(
                    "custom-input",
                    submitAttempted && !numberOfTicketsWithFeeCap
                  )}
                  disabled={raffleId && !daysRemaining}
                  name="numberOfTicketsWithFeeCap"
                  onChange={(e) =>
                    onChange(
                      "numberOfTicketsWithFeeCap",
                      parseNumber(e.target.value)
                    )
                  }
                  tabIndex={50}
                  value={numberOfTicketsWithFeeCap}
                  decimalScale={2}
                  thousandSeparator={true}
                />
                <label className="accent-text small-text ticket-fee-input-label">
                  Tickets
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-align-center mb-16">
            <div className="flex flex-align-center">
              <label className="accent-text small-text raffle-label">CAD</label>
              <NumberFormat
                className={getErrorClassName(
                  "custom-input dollar-input",
                  submitAttempted && !cadTicketPrice
                )}
                disabled={raffleId && (!daysRemaining || isActive)}
                name="cadTicketPrice"
                onChange={(e) =>
                  onChange("cadTicketPrice", parseNumber(e.target.value))
                }
                tabIndex={30}
                value={cadTicketPrice}
                decimalScale={2}
                thousandSeparator={true}
              />
            </div>
            <div className="flex flex-align-center ml-40">
              <label className="accent-text small-text raffle-label">
                Ticket Fee
              </label>
              <NumberFormat
                className={getErrorClassName(
                  "custom-input percentage-input",
                  submitAttempted && !ticketFeePercentage
                )}
                disabled={raffleId && !daysRemaining}
                name="ticketFeePercentage"
                onChange={(e) =>
                  onChange("ticketFeePercentage", parseNumber(e.target.value))
                }
                tabIndex={60}
                value={ticketFeePercentage}
                decimalScale={2}
                isAllowed={({ value }) => value < 100}
                thousandSeparator={true}
              />
            </div>
          </div>
          <div className="flex flex-align-center mb-16">
            <div className="flex flex-align-center">
              <label className="accent-text small-text raffle-label">GBP</label>
              <NumberFormat
                className={getErrorClassName(
                  "custom-input pound-input",
                  submitAttempted && !gbpTicketPrice
                )}
                disabled={raffleId && (!daysRemaining || isActive)}
                name="gbpTicketPrice"
                onChange={(e) =>
                  onChange("gbpTicketPrice", parseNumber(e.target.value))
                }
                tabIndex={40}
                value={gbpTicketPrice}
                decimalScale={2}
                thousandSeparator={true}
              />
            </div>
            <div className="flex flex-align-center ml-40">
              <label className="accent-text small-text raffle-label">
                Processing Fee
                <span className="tooltip-container">
                  <i className="material-icons medium-text ml-4">info</i>
                  <span className="tooltip">For CC and PayPal purchases</span>
                </span>
              </label>
              <NumberFormat
                className={getErrorClassName(
                  "custom-input percentage-input",
                  submitAttempted && !cociCcProcessingFeePercentage
                )}
                disabled={raffleId && (!daysRemaining || isActive)}
                name="cociCcProcessingFeePercentage"
                onChange={(e) =>
                  onChange(
                    "cociCcProcessingFeePercentage",
                    parseNumber(e.target.value)
                  )
                }
                tabIndex={70}
                value={cociCcProcessingFeePercentage}
                decimalScale={0}
                isAllowed={({ value }) => value < 100}
                thousandSeparator={true}
              />
            </div>
          </div>
          {!!raffleId &&
            (isActive ? (
              <span className="accent-text">
                * CC processing fee and ticket prices are locked in for active
                Grand Draws
              </span>
            ) : !daysRemaining ? (
              <span className="accent-text">
                * Ticket Prices and Info cannot be updated for past Grand Draws
              </span>
            ) : (
              ""
            ))}
        </div>
        <div className="new-raffle-step-section">
          <p className="medium-text fw-700 mb-16">Currency Conversions</p>
          <p className="accent-text mb-16">
            This conversion rate is not used for the ticket prices. It's only
            used to tally up the total amount raised in USD, by converting the
            orders in foreign currencies to USD using this rate.
          </p>
          <div className="flex flex-align-center mb-16">
            <label className="accent-text small-text raffle-label">
              CAD to USD
            </label>
            <NumberFormat
              className={getErrorClassName(
                "custom-input dollar-input",
                isPublished && !fromCadConversion
              )}
              name="fromCadConversion"
              onChange={(e) =>
                onChange("fromCadConversion", parseNumber(e.target.value))
              }
              tabIndex={71}
              value={fromCadConversion}
              decimalScale={6}
              thousandSeparator={true}
            />
          </div>
          <div className="flex flex-align-center mb-16">
            <label className="accent-text small-text raffle-label">
              GBP to USD
            </label>
            <NumberFormat
              className={getErrorClassName(
                "custom-input dollar-input",
                isPublished && !fromGbpConversion
              )}
              name="fromGbpConversion"
              onChange={(e) =>
                onChange("fromGbpConversion", parseNumber(e.target.value))
              }
              tabIndex={72}
              value={fromGbpConversion}
              decimalScale={6}
              thousandSeparator={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
