import React from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../shared/ConfirmationModal";
import FadeOutErrorMessage from "../shared/FadeOutErrorMessage";
import Loader from "../common/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import { Unauthorized } from "../../lib/coc-common-components";
import { notify } from "react-notify-toast";

import CloneEventConfigsModal from "./configuration/CloneEventConfigsModal";
import EventConfigTabs from "./configuration/EventConfigTabs";
import EventParticipants from "./configuration/participants/EventParticipants";
import EventPricing from "./configuration/pricing/EventPricing";
import EventSettings from "./configuration/settings/EventSettings";
import EventWebPageSettings from "./configuration/webpage/EventWebPageSettings";
import EventShluchimControls from "./configuration/shluchimControls/EventShluchimControls";
import EventTermsAndConditions from "./configuration/termsAndConditions/EventTermsAndConditions";
import EventTours from "./configuration/tours/EventTours";
import EventTrackOverrideConfigs from "./configuration/EventTrackOverrideConfigs";
import EventTracksTabs from "./configuration/EventTracksTabs";

import TripEventsApi from "../../services/resources/TripEventsApi";
import SystemApi from "../../services/resources/SystemApi";
import AuthClient from "../../services/resources/AuthClient";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import {
  getFormattedValuesForForm,
  removePropFromObject,
  replaceValuesInObject,
} from "../../lib";
import { getRequiredEventFields, isTravelTripType } from "./TripEventLogic";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import axios from "axios";
import {
  TripAcceptancePolicyOptions,
  TripConfigApplicableFor,
  TripTypes,
} from "./TripEventConsts";

export default class TripEventConfigurationPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,

      chabadHouses: [],

      errorMessage: "",
      loading: true,

      event: null,
      initialEvent: null,

      requiredEventFields: {},
      requestedPageLeave: false,

      saveEventErrorMessage: "",
      saveEventLoading: false,
      saveEventDraftErrorMessage: "",
      saveEventDraftLoading: false,

      showCloneEventConfigsModal: false,
      showSaveConfirmationModal: false,
      submitAttempted: false,

      systemLists: {
        optInOutOptions: [],
        tripAcceptancePolicyOptions: [],
        tripTypes: [],
      },

      tabIndex: 0,
      tabs: [
        { icon: "settings", id: "settings", component: EventSettings },
        { icon: "people", id: "participants" },
        { icon: "tune", id: "shluchim-controls" },
        { icon: "payment", id: "pricing", component: EventPricing },
        { icon: "directions_subway", id: "tours" },
        { icon: "list_alt", id: "terms", component: EventTermsAndConditions },
        { icon: "language", id: "webpage", component: EventWebPageSettings },
      ],
      trackIndex: 0,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.TripFullEdit)) {
      this.setState({ authorized: false });
    }

    const { route, router } = this.props;

    router.setRouteLeaveHook(route, this.onLeave);

    this.getEventDetails();

    this.getSystemLists();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { event, initialEvent, requestedPageLeave } = this.state;

    if (!requestedPageLeave && !_isEqual(event, initialEvent)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getPageURI = () => {
    const { tabIndex, tabs, trackIndex } = this.state;
    const {
      location: { pathname },
    } = this.props;

    return `${pathname}?tab=${encodeURIComponent(tabs[tabIndex].id)}${
      trackIndex > 0 ? `&track=${trackIndex}` : ""
    }`;
  };

  getChabadHousesForParticipants = async (programId, scheduleId) => {
    try {
      const chabadHousesResponse = await AuthClient.get(
        `Programs/${programId}/schedules/${scheduleId}/participatingChabadHouses`,
        { cancelToken: this.apiSignal.token }
      );
      this.setState({ chabadHouses: chabadHousesResponse.data.payload || [] });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve Program Event settings. Please try again."
          ),
          loading: false,
        });
      }
    }
  };

  onSetEventType = (onUpdateEventTabs) => {
    const {
      chabadHouses,
      event: {
        includesAllShluchim,
        isTravelTrip,
        programID,
        programScheduleID,
      },
    } = this.state;

    // update event tabs based on event type
    this.updateEventTabs(
      {
        isTravelTrip,
        includesAllShluchim,
      },
      onUpdateEventTabs
    );

    // get chabad houses for participants tab if relevant for event
    if (!isTravelTrip && !includesAllShluchim && !chabadHouses.length) {
      this.getChabadHousesForParticipants(programID, programScheduleID);
    }
  };

  getEventDetails = () => {
    this.setState({ loading: true }, () => {
      TripEventsApi.getTripEvent(
        this.apiSignal.token,
        this.props.params.scheduleId,
        true
      )
        .then((eventDetails) => {
          const newState = {
            loading: false,
          };

          const event = getFormattedValuesForForm(eventDetails);

          newState.initialEvent = event;
          newState.event = _cloneDeep(event);

          //when loading a new event, show the clone event configs modal
          if (!event.id) {
            newState.showCloneEventConfigsModal = true;
          }

          this.setState(newState, () => {
            const {
              location: {
                query: { tab, track },
              },
            } = this.props;

            const { tabs } = this.state;

            const tabIndex = tabs
              .map((tab) => tab.id)
              .indexOf(decodeURIComponent(tab));

            const onUpdateEventTabs = () => {
              //if Url has tab, go to tab
              if (tabIndex > 0) {
                this.toTab(tabIndex);
              }
            };

            this.onSetEventType(onUpdateEventTabs);

            //if Url has trackIndex, go to track
            if (track) {
              this.toTrack(track);
            }
          });
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            this.setState({
              errorMessage: ApiCallErrorMessageHandler(err),
              loading: false,
            });
          }
        });
    });
  };

  updateEventTabs = (
    {
      isTravelTrip = this.state.event?.isTravelTrip,
      isJewishUTrip = this.state.event?.type === TripTypes.JewishU,
      includesAllShluchim = this.state.event?.includesAllShluchim,
    },
    onUpdate
  ) => {
    let updatedTabs = [...this.state.tabs];

    const toursTabIndex = updatedTabs.findIndex((t) => t.id === "tours");
    updatedTabs[toursTabIndex].component = isTravelTrip ? null : EventTours;
    updatedTabs[toursTabIndex].disabledTooltip = isTravelTrip
      ? "Tours configuration is not available for Travel events"
      : "";

    const shluchimControlsTabIndex = updatedTabs.findIndex(
      (t) => t.id === "shluchim-controls"
    );
    updatedTabs[shluchimControlsTabIndex].component = isTravelTrip
      ? null
      : EventShluchimControls;
    updatedTabs[shluchimControlsTabIndex].disabledTooltip = isTravelTrip
      ? "Shluchim controls configuration is not available for Travel events"
      : "";

    const participantsTabIndex = updatedTabs.findIndex(
      (t) => t.id === "participants"
    );
    updatedTabs[participantsTabIndex].component =
      isTravelTrip || includesAllShluchim ? null : EventParticipants;
    updatedTabs[participantsTabIndex].disabledTooltip = isTravelTrip
      ? "Participants configuration is not available for Travel events"
      : includesAllShluchim
      ? "Participants configuration is not available for events that include all Shluchim"
      : "";

    this.setState({ tabs: updatedTabs }, onUpdate);
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists([
        "optInOutOptions",
        "regions",
        "tripAcceptancePolicyOptions",
        "tripTypes",
      ]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
        });
      }
    }
  };

  onAddEventTrack = async () => {
    const {
      event: { tracks },
    } = this.state;

    const newTrack = _cloneDeep({
      ...tracks[0],
      trackName: "",
    });
    replaceValuesInObject(
      newTrack,
      (_, key) => key === "id" || key === "sortOrderPosition",
      () => 0
    );

    await this.onChangeEvent("tracks", [...tracks, newTrack]);

    this.toTrack(tracks.length);
  };

  onDeleteEventTrack = async (index) => {
    const {
      event: { tracks },
      trackIndex,
    } = this.state;

    if (trackIndex === index) {
      await this.toTrack(index - 1);
    }

    this.onChangeEvent(
      "tracks",
      tracks.filter((track, i) => i !== index)
    );
  };

  onChangeEvent = (name, value, otherUpdates) => {
    let event = _cloneDeep(this.state.event);
    _set(event, name, value);

    if (otherUpdates) {
      Object.keys(otherUpdates).forEach((update) =>
        _set(event, update, otherUpdates[update])
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ event }, () => {
        resolve();
      });
    });
  };

  onChangeEventEvt = ({ target: { name, value } }) => {
    return this.onChangeEvent(name, value);
  };

  onChangeEventType = async (name, value) => {
    const isTravelTrip = isTravelTripType(value);
    await this.onChangeEvent(name, value, { isTravelTrip });

    this.onSetEventType();
  };

  onCancelEventChanges = async () => {
    const {
      event: { programID },
      initialEvent,
      initialEvent: { tracks: initialTracks },
      trackIndex,
    } = this.state;
    const isNewEvent = !initialEvent.id;

    if (!initialTracks[trackIndex]) {
      await this.toTrack(0);
    }

    this.setState(
      {
        event: this.state.initialEvent,
        requestedPageLeave: isNewEvent,
        submitAttempted: false,
      },
      () => {
        if (isNewEvent) {
          browserHistory.push(`/programs/${programID}`);
        }
      }
    );
  };

  getEventForSubmission = (publish = false) => {
    const eventForSubmission = _cloneDeep({
      ...this.state.event,
      isPublished: publish,
    });

    // Setting non-nullable prices to 0
    if (
      !eventForSubmission.shliachPrice &&
      eventForSubmission.shliachPrice !== 0
    ) {
      eventForSubmission.shliachPrice = 0;
    }
    eventForSubmission.tracks.forEach((track) => {
      if (!track.regularStudentPrice && track.regularStudentPrice !== 0) {
        track.regularStudentPrice = 0;
      }
    });

    // revert settings not relevant based on trip type (to accommodate potential changes to trip type after settings were already set):
    if (eventForSubmission.isTravelTrip) {
      // shluchim controls, pricing, and T&Cs are not available for travel trips
      eventForSubmission.adminAcceptancePolicyOption =
        TripAcceptancePolicyOptions.YesAcceptance;
      eventForSubmission.defaultShliachAcceptancePolicy = null;
      eventForSubmission.canShluchimOverridePrices = false;
      eventForSubmission.canShluchimIssuePromoCodes = false;
      eventForSubmission.shliachPrice = 0;
      eventForSubmission.promoCodes = eventForSubmission.promoCodes.filter(
        (pc) => pc.appliesTo === TripConfigApplicableFor.Students
      );
      eventForSubmission.termsAndConditions =
        eventForSubmission.termsAndConditions.filter(
          (tc) => tc.appliesTo === TripConfigApplicableFor.Students
        );

      // tours are not available for travel trips
      eventForSubmission.tours = [];

      // passport id is not relevant for Conference trips
      if (eventForSubmission.type === TripTypes.Conference) {
        eventForSubmission.isPassportIdRequired = false;
      }
    } else {
      // student deposit and region restriction are not available for non-travel trips
      eventForSubmission.tracks.forEach((t) => {
        t.studentDepositAmount = null;
        t.regionIDs = [];
      });

      // other travel trip fields not available for non-travel trips
      eventForSubmission.studentAcceptanceEmailContent = null;
      eventForSubmission.tripLocation = null;
      eventForSubmission.hasParentLetters = false;
      eventForSubmission.isMarketingConsentRequired = false;
      eventForSubmission.isPassportIdRequired = false;
    }

    if (eventForSubmission.type === TripTypes.JewishU) {
      // early bird pricing is not available for JewishU trips
      eventForSubmission.earlyBirdStudentPrice = null;
      eventForSubmission.earlyBirdStudentDeadline = null;
    } else {
      // reward credit value is available for only JewishU trips
      eventForSubmission.rewardCreditValue = null;

      //trip highlights are only available for JewishU trips
      eventForSubmission.videoURL = null;
      eventForSubmission.tripItineraryHighlights = [];
      eventForSubmission.tripGalleryImages = [];
    }

    return eventForSubmission;
  };

  onSaveEventDraft = () => {
    const eventDraft = this.getEventForSubmission();

    this.setState(
      {
        saveEventDraftErrorMessage: "",
        saveEventDraftLoading: true,
      },
      () => {
        TripEventsApi.updateTripEvent(this.apiSignal.token, eventDraft)
          .then((savedEvent) => {
            const event = getFormattedValuesForForm(savedEvent);
            this.setState(
              {
                initialEvent: event,
                event: _cloneDeep(event),
                saveEventDraftLoading: false,
                submitAttempted: false,
              },
              () => {
                notify.show(
                  "Your Program Event draft has been saved",
                  "success"
                );
              }
            );
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                saveEventDraftErrorMessage: ApiCallErrorMessageHandler(err),
                saveEventDraftLoading: false,
              });
            }
          });
      }
    );
  };

  onSaveEvent = () => {
    const requiredEventFields = getRequiredEventFields(this.state.event);
    this.setState({
      requiredEventFields,
      showSaveConfirmationModal: true,
      submitAttempted: true,
    });
  };

  saveEvent = () => {
    const publishNow = !this.state.event.isPublished;
    const eventForPublish = this.getEventForSubmission(true);

    this.setState(
      {
        saveEventErrorMessage: "",
        saveEventLoading: true,
      },
      () => {
        TripEventsApi.updateTripEvent(this.apiSignal.token, eventForPublish)
          .then((savedEvent) => {
            const event = getFormattedValuesForForm(savedEvent);
            this.setState(
              {
                initialEvent: event,
                event: _cloneDeep(event),
                saveEventLoading: false,
                showSaveConfirmationModal: false,
                submitAttempted: false,
              },
              () => {
                notify.show(
                  `Your Program Event has been ${
                    publishNow ? "published" : "saved"
                  }`,
                  "success"
                );
              }
            );
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                saveEventErrorMessage: ApiCallErrorMessageHandler(err),
                saveEventLoading: false,
              });
            }
          });
      }
    );
  };

  onCloneEvent = async (eventClone) => {
    const { event: currentEvent } = this.state;

    //remove id
    removePropFromObject(eventClone, "id");
    //reverse isPublished from the event that's being cloned
    eventClone.isPublished = false;
    //preserve program schedule settings from current event
    eventClone.programID = currentEvent.programID;
    eventClone.programURL = currentEvent.programURL;
    eventClone.programScheduleID = currentEvent.programScheduleID;
    eventClone.programScheduleName = currentEvent.programScheduleName;
    eventClone.includesAllShluchim = currentEvent.includesAllShluchim;
    eventClone.daysToStart = currentEvent.daysToStart;
    eventClone.tripStartDate = currentEvent.tripStartDate;
    eventClone.tripEndDate = currentEvent.tripEndDate;
    eventClone.shluchimEnrollmentStart = currentEvent.shluchimEnrollmentStart;
    eventClone.shluchimEnrollmentEnd = currentEvent.shluchimEnrollmentEnd;
    eventClone.hasStartedShluchimEnrollment =
      currentEvent.hasStartedShluchimEnrollment;
    eventClone.hasStudentRegistrations = currentEvent.hasStudentRegistrations;

    this.setState(
      {
        event: getFormattedValuesForForm(eventClone),
        showCloneEventConfigsModal: false,
        tabIndex: 0, // reset tab index
      },
      this.onSetEventType
    );
  };

  toTab = (tabIndex, next) => {
    const { tabIndex: prevTabIndex, tabs } = this.state;

    if (prevTabIndex !== tabIndex) {
      let newTabIndex = tabIndex;

      if (!tabs[tabIndex].component) {
        //toTab is disabled
        newTabIndex = next ? tabIndex + 1 : tabIndex - 1; //go another next/prev
        if (!tabs[newTabIndex]) {
          //now at last or first tab
          this.toTab(tabIndex, !!(newTabIndex < 0));
          return;
        } else if (!tabs[newTabIndex].component) {
          //this tab is disabled as well
          this.toTab(newTabIndex, next);
          return;
        }
      }

      this.scrollToTop();
      this.setState({ tabIndex: newTabIndex }, () => {
        browserHistory.replace(this.getPageURI());
      });
    }
  };

  toTrack = (trackIndex) => {
    const { event, trackIndex: prevTrackIndex } = this.state;

    if (parseInt(trackIndex) >= 0 && parseInt(trackIndex) !== prevTrackIndex) {
      let newTrackIndex = parseInt(trackIndex);

      const tracks = (event && event.tracks) || [];
      if (!tracks[newTrackIndex]) {
        newTrackIndex = 0;
      }

      return new Promise((resolve) => {
        this.scrollToTop();
        this.setState({ trackIndex: newTrackIndex }, () => {
          browserHistory.replace(this.getPageURI());
          resolve();
        });
      });
    }
  };

  scrollToTop = () => {
    const scrollWindow = document.getElementById("scroll-window");
    if (scrollWindow) {
      scrollWindow.scrollTo({ left: 0, top: 0 });
    }
  };

  render() {
    const {
      routeParams: { scheduleId },
    } = this.props;
    const {
      authorized,
      chabadHouses,
      errorMessage,
      event,
      initialEvent,
      loading,
      requiredEventFields: { incompleteFieldsByTrack, isIncomplete },
      saveEventErrorMessage,
      saveEventLoading,
      saveEventDraftErrorMessage,
      saveEventDraftLoading,
      showCloneEventConfigsModal,
      showSaveConfirmationModal,
      submitAttempted,
      systemLists,
      tabIndex,
      tabs,
      trackIndex,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="program-settings-page page">
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !event ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              {errorMessage ||
                "Sorry, something went wrong and we could not retrieve Program Event settings. Please try again."}
            </p>
          </div>
        ) : (
          <React.Fragment>
            <div className="program-settings-page-subheader">
              <ProgramBreadcrumbsHeader
                className="mt-16"
                getPageTitle={(sched) =>
                  sched.programAdminBaseUrl
                    ? "Advanced Settings"
                    : `${sched.scheduleName} - Advanced Settings`
                }
                getPrevPages={(sched) =>
                  sched.programAdminBaseUrl
                    ? [
                        {
                          path: `${sched.programAdminBaseUrl}/${sched.scheduleID}`,
                          title: sched.scheduleName,
                        },
                      ]
                    : null
                }
                scheduleId={scheduleId}
              />
              <div className="program-settings-page-subheader-content">
                <EventTracksTabs
                  addTrack={this.onAddEventTrack}
                  deleteTrack={this.onDeleteEventTrack}
                  eventId={event.id}
                  eventIsPublished={event.isPublished}
                  hasStartedShluchimEnrollment={
                    event.hasStartedShluchimEnrollment
                  }
                  toTrack={this.toTrack}
                  trackIndex={trackIndex}
                  tracks={event.tracks}
                />
                <div className="program-settings-page-subheader-right">
                  <button
                    className="btn custom-btn btn-cancel btn-medium ml-16 uppercase-text"
                    disabled={
                      saveEventDraftLoading || _isEqual(initialEvent, event)
                    }
                    onClick={this.onCancelEventChanges}
                  >
                    Cancel
                  </button>
                  {!event.isPublished && (
                    <button
                      className="btn custom-btn btn-accent btn-medium ml-16 uppercase-text"
                      disabled={
                        saveEventDraftLoading || _isEqual(initialEvent, event)
                      }
                      onClick={this.onSaveEventDraft}
                    >
                      {saveEventDraftLoading ? "Saving..." : "Save Draft"}
                    </button>
                  )}
                  {!saveEventDraftLoading && saveEventDraftErrorMessage && (
                    <FadeOutErrorMessage
                      message={saveEventDraftErrorMessage}
                      onTimeout={() =>
                        this.setState({
                          saveEventDraftErrorMessage: "",
                        })
                      }
                    />
                  )}
                  <button
                    className="btn custom-btn btn-accent btn-medium ml-16 uppercase-text"
                    disabled={
                      saveEventLoading ||
                      (event.isPublished && _isEqual(initialEvent, event))
                    }
                    onClick={this.onSaveEvent}
                  >
                    {event.isPublished ? "Save" : "Publish"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex" style={{ alignItems: "flex-start" }}>
              {
                <React.Fragment>
                  {trackIndex === 0 && (
                    <EventConfigTabs
                      tabIndex={tabIndex}
                      tabs={tabs}
                      toTab={this.toTab}
                    />
                  )}
                  <div className="program-settings-page-content">
                    {React.createElement(
                      trackIndex === 0
                        ? tabs[tabIndex].component
                        : EventTrackOverrideConfigs,
                      {
                        chabadHouses,
                        event,
                        initialEvent,
                        onChange: this.onChangeEvent,
                        onChangeEvt: this.onChangeEventEvt,
                        onChangeType: this.onChangeEventType,
                        submitAttempted,
                        systemLists,
                        track: event.tracks[trackIndex],
                        trackFieldPrefix: `tracks[${trackIndex}]`,
                      }
                    )}

                    {trackIndex === 0 && (
                      <div className="program-settings-page-btns flex flex-align-center flex-justify-space">
                        {tabIndex > 0 ? (
                          <button
                            className="btn btn-light btn-medium custom-btn"
                            onClick={() => this.toTab(tabIndex - 1)}
                          >
                            Prev
                          </button>
                        ) : (
                          <div />
                        )}
                        {(tabs[tabs.length - 1].component //last enabled tab might be second-to-last option
                          ? tabIndex < tabs.length - 1
                          : tabIndex < tabs.length - 2) && (
                          <button
                            className="btn custom-btn btn-accent btn-medium ml-16"
                            onClick={() => this.toTab(tabIndex + 1, true)}
                          >
                            {tabs[tabIndex].id === "tours" &&
                            (!event.tours || !event.tours.length)
                              ? "Skip Tours"
                              : "Next"}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              }
            </div>
            <ConfirmationModal
              cancel={() =>
                this.setState({
                  saveEventErrorMessage: "",
                  showSaveConfirmationModal: false,
                  ...(!isIncomplete ? { submitAttempted: false } : {}),
                  //if did not show incomplete message at confirmation, do not want field validation to begin showing up if incomplete fields are introduced
                })
              }
              cancelText={isIncomplete ? "OK" : "Cancel"}
              confirm={this.saveEvent}
              confirmText={event.isPublished ? "Save" : "Publish"}
              errorMessage={saveEventErrorMessage}
              message={
                isIncomplete ? (
                  <div>
                    <p>
                      Please update the following required or invalid fields
                      before {event.isPublished ? "saving" : "publishing"} this
                      Program Event:
                    </p>
                    {incompleteFieldsByTrack &&
                      Object.keys(incompleteFieldsByTrack).map((trackKey) => (
                        <div key={trackKey} className="accent-text-dark">
                          <p
                            className="fw-700"
                            style={{ textDecoration: "underline" }}
                          >
                            {trackKey}
                          </p>
                          {Object.keys(incompleteFieldsByTrack[trackKey]).map(
                            (categoryKey) => (
                              <div key={categoryKey}>
                                <p className="fw-700">{categoryKey}:</p>
                                {incompleteFieldsByTrack[trackKey][
                                  categoryKey
                                ].map((fieldMessage, fieldIndex) => (
                                  <p key={fieldIndex}>&bull; {fieldMessage}</p>
                                ))}
                              </div>
                            )
                          )}
                        </div>
                      ))}
                  </div>
                ) : event.isPublished ? (
                  "Save changes to this Program Event?"
                ) : (
                  "You are about to publish this Program Event. This can't be undone. Would you like to proceed?"
                )
              }
              noConfirm={isIncomplete}
              loading={saveEventLoading}
              show={showSaveConfirmationModal}
            />
            <CloneEventConfigsModal
              close={() => this.setState({ showCloneEventConfigsModal: false })}
              onCloneEvent={this.onCloneEvent}
              show={showCloneEventConfigsModal}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
